import { ApiHelper } from './api.helper';
import { IStaffStatistics } from '../types/staff-statistics.type';
import { HttpUtils } from '../utils/http.utils';
import { IFilters, IRequestParams } from '../views/StaffStatistics/types';

export const fetchStaffStatistics = async (filters: IFilters): Promise<IStaffStatistics[]> => {
  const queryParams = HttpUtils.toQueryParams<IFilters, IRequestParams>(filters);
  const url = `${ApiHelper.makeUrl('staff/statistics')}?${queryParams}`;
  const response = await fetch(url, ApiHelper.makeOptions());
  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message);
  }

  return data;
};

export const downloadStaffStatistics = async (filters: IFilters): Promise<Blob> => {
  const queryParams = HttpUtils.toQueryParams<IFilters, IRequestParams>(filters);
  const url = `${ApiHelper.makeUrl('staff/statistics/export')}?${queryParams}`;

  const response = await fetch(url, ApiHelper.makeOptions());
  const data = response.blob();

  if (!response.ok) {
    const responseData = await response.json();

    throw new Error(await responseData.message);
  }

  return data;
};
