import React from 'react';

import { ChartData } from 'chart.js';
import PropTypes from 'prop-types';
import { Pie } from 'react-chartjs-2';

import { CHART_COLOR } from '../../../../constants/chart.constant';
import { PRODUCT_TYPE_LIST } from '../../../../constants/product-type.constant';
import { IStatisticsStaffItem } from '../../../../types/statistics.type';
import { StatisticsStaffShape } from '../../shapes';

interface IProps {
  school: string;
  data: IStatisticsStaffItem;
}

const StatisticsStaffChart: React.FC<IProps> = React.memo(({ school, data }) => {
  const chartData: ChartData<'pie'> = {
    labels: PRODUCT_TYPE_LIST,
    datasets: [
      {
        label: ' ',
        data: PRODUCT_TYPE_LIST.map((type) => data[type]).flat(),
        backgroundColor: [
          CHART_COLOR.red.background,
          CHART_COLOR.yellow.background,
          CHART_COLOR.green.background,
        ],
        borderColor: [CHART_COLOR.red.border, CHART_COLOR.yellow.border, CHART_COLOR.green.border],
        borderWidth: 1,
      },
    ],
  };

  return (
    <Pie
      data-testid="chart-pie"
      data={chartData}
      options={{
        plugins: {
          title: {
            display: true,
            text: school.toUpperCase(),
            align: 'center',
          },
        },
      }}
    />
  );
});

StatisticsStaffChart.propTypes = {
  school: PropTypes.string.isRequired,
  data: StatisticsStaffShape.isRequired,
};

export default StatisticsStaffChart;
