import React from 'react';

import { Table, TableContainer } from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';

import { IPreInscriptionSummary } from '../../../../types/pre-inscription-summary.type';
import { PreInscriptionSummaryShape } from '../../shapes';
import PreInscriptionListSummaryBody from '../PreInscriptionListSummaryBody';
import PreInscriptionListSummaryHead from '../PreInscriptionListSummaryHead';

interface IProps {
  inscriptions: IPreInscriptionSummary[];
}

const PreInscriptionSummaryList: React.FC<IProps> = React.memo(({ inscriptions }) => (
  <Grid container mt={3}>
    <Grid item xs={12}>
      <TableContainer component={Paper}>
        <Table>
          <PreInscriptionListSummaryHead />
          <PreInscriptionListSummaryBody inscriptions={inscriptions} />
        </Table>
      </TableContainer>
    </Grid>
  </Grid>
));

PreInscriptionSummaryList.propTypes = {
  inscriptions: PropTypes.arrayOf(PreInscriptionSummaryShape.isRequired).isRequired,
};

export default PreInscriptionSummaryList;
