import PropTypes from 'prop-types';

import { IProductType } from '../../types/product-type.type';

export const StaffStatisticsLevelShape = PropTypes.shape({
  schoolLevel: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  status: PropTypes.objectOf(PropTypes.number.isRequired).isRequired,
});

export const StaffStatisticsShape = PropTypes.shape({
  groupCode: PropTypes.string.isRequired,
  productType: PropTypes.oneOf(Object.values(IProductType)).isRequired,
  total: PropTypes.number.isRequired,
  status: PropTypes.objectOf(PropTypes.number.isRequired).isRequired,
  levels: PropTypes.arrayOf(StaffStatisticsLevelShape.isRequired).isRequired,
});
