import PropTypes from 'prop-types';

export const PreInscriptionSummaryStatisticsItemShape = PropTypes.shape({
  accepted: PropTypes.number.isRequired,
  acceptedFraction: PropTypes.number.isRequired,
  refused: PropTypes.number.isRequired,
  refusedFraction: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
});

export const PreInscriptionSummaryStatisticsShape = PropTypes.shape({
  responseDa: PreInscriptionSummaryStatisticsItemShape.isRequired,
  responseRapo: PreInscriptionSummaryStatisticsItemShape.isRequired,
  afterRapo: PreInscriptionSummaryStatisticsItemShape.isRequired,
});

export const PreInscriptionSummaryShape = PropTypes.shape({
  groupCode: PropTypes.string.isRequired,
  new: PreInscriptionSummaryStatisticsShape.isRequired,
  old: PreInscriptionSummaryStatisticsShape.isRequired,
  total: PreInscriptionSummaryStatisticsShape.isRequired,
});
