import React from 'react';

import { TableBody } from '@mui/material';
import PropTypes from 'prop-types';

import { IPreInscriptionSummary } from '../../../../types/pre-inscription-summary.type';
import { PreInscriptionSummaryShape } from '../../shapes';
import PreInscriptionListSummaryGroup from '../PreInscriptionListSummaryGroup';

interface IProps {
  inscriptions: IPreInscriptionSummary[];
}

const PreInscriptionListSummaryBody: React.FC<IProps> = React.memo(({ inscriptions }) => (
  <TableBody>
    {inscriptions.map((inscription) => (
      <PreInscriptionListSummaryGroup key={inscription.groupCode} inscription={inscription} />
    ))}
  </TableBody>
));

PreInscriptionListSummaryBody.propTypes = {
  inscriptions: PropTypes.arrayOf(PreInscriptionSummaryShape.isRequired).isRequired,
};

export default PreInscriptionListSummaryBody;
