import React from 'react';

import { TableCell, TableRow, Theme } from '@mui/material';
import { SxProps } from '@mui/system';

import { IPreInscriptionSummaryStatistics } from '../../../../types/pre-inscription-summary.type';
import { PreInscriptionSummaryStatisticsShape } from '../../shapes';

interface IProps {
  statistics: IPreInscriptionSummaryStatistics;
}

const sxCell: SxProps<Theme> = { textAlign: 'center' };

const PreInscriptionListSummaryFraction: React.FC<IProps> = React.memo(({ statistics }) => (
  <TableRow>
    <TableCell sx={sxCell}>{statistics.responseDa.acceptedFraction}%</TableCell>
    <TableCell sx={sxCell}>{statistics.responseDa.refusedFraction}%</TableCell>
    <TableCell sx={sxCell}>100%</TableCell>
    <TableCell sx={sxCell}>{statistics.afterRapo.acceptedFraction}%</TableCell>
    <TableCell sx={sxCell}>{statistics.afterRapo.refusedFraction}%</TableCell>
    <TableCell sx={sxCell}>100%</TableCell>
    <TableCell sx={sxCell}>{statistics.responseRapo.acceptedFraction}%</TableCell>
    <TableCell sx={sxCell}>{statistics.responseRapo.refusedFraction}%</TableCell>
  </TableRow>
));

PreInscriptionListSummaryFraction.propTypes = {
  statistics: PreInscriptionSummaryStatisticsShape.isRequired,
};

export default PreInscriptionListSummaryFraction;
