import React from 'react';

import {
  IPreInscriptionSummary,
  IPreInscriptionSummaryStatistics,
} from '../../../../types/pre-inscription-summary.type';
import { IRegistrationType } from '../../../../types/registration-type';
import { PreInscriptionSummaryShape } from '../../shapes';
import PreInscriptionListSummarySection from '../PreInscriptionListSummarySection';

interface IProps {
  inscription: IPreInscriptionSummary;
}

const types = [IRegistrationType.new, IRegistrationType.old, 'total'] as Array<
  keyof IPreInscriptionSummary
>;

const PreInscriptionListSummaryGroup: React.FC<IProps> = React.memo(({ inscription }) => (
  <>
    {types.map((key) => (
      <PreInscriptionListSummarySection
        key={`summary_item_${key}`}
        groupCode={inscription.groupCode}
        statistics={inscription[key] as IPreInscriptionSummaryStatistics}
        type={key}
      />
    ))}
  </>
));

PreInscriptionListSummaryGroup.propTypes = {
  inscription: PreInscriptionSummaryShape.isRequired,
};

export default PreInscriptionListSummaryGroup;
