import React from 'react';

import Grid from '@mui/material/Grid';
import { SxProps } from '@mui/system';
import { useTranslation } from 'react-i18next';

import FormHandleControl from '../../../../components/FormHandleControl';
import FormSchoolSelect from '../../../../components/FormSchoolSelect';
import FormSelect from '../../../../components/FormSelect';
import FormSelectMultiple from '../../../../components/FormSelectMultiple';
import PaperWrapper from '../../../../components/PaperWrapper';
import { useFetchDefaultFiltersData } from '../../../../hooks/useFetchDefaultFiltersData';
import { useFetchPreInscriptionSummaryFiltersData } from '../../../../hooks/useFetchPreInscriptionSummaryFiltersData';
import { useFilters } from '../../../../hooks/useFilters';
import { FilterListShape } from '../../../../shapes/filter-list.shape';
import { IFilterList } from '../../../../types/filter-list.type';
import { IFilters } from '../../types';

const formFieldOptions: SxProps = { m: 1, width: 250 };

const PreInscriptionSummaryFilters: React.FC<IFilterList<IFilters>> = React.memo(
  ({ onSubmit, onExport, isSubmitProcess, isExportProcess }) => {
    const { t } = useTranslation();

    const { schools, schoolYears, schoolLevelGroups, isLoading } =
      useFetchPreInscriptionSummaryFiltersData();

    const defaultFilters = useFetchDefaultFiltersData<IFilters>('preInscriptionSummary');

    const { filters, handleSelectChange, handleMultipleChoiceChange } =
      useFilters<IFilters>(defaultFilters);

    const handleSubmit = (event: React.FormEvent) => {
      event.preventDefault();

      if (isLoading) {
        return;
      }

      onSubmit(filters);
    };

    return (
      <PaperWrapper isLoading={isLoading}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={1} direction="row" alignItems="flex-start">
            <FormSchoolSelect
              onChange={handleSelectChange}
              schools={schools}
              value={filters.school}
              controlSx={formFieldOptions}
            />

            <FormSelect
              label={t('general.school_year')}
              name="schoolYear"
              value={filters.schoolYear}
              items={schoolYears}
              onChange={handleSelectChange}
              controlSx={formFieldOptions}
            />

            <FormSelectMultiple
              name="groupCode"
              value={filters.groupCode}
              onChoose={(value) => handleMultipleChoiceChange('groupCode', value)}
              label={t('pre_inscriptions_summary.group_code')}
              items={schoolLevelGroups}
              controlSx={formFieldOptions}
            />
          </Grid>

          <FormHandleControl
            isSubmitProcess={isSubmitProcess}
            isExportProcess={isExportProcess}
            onExport={() => onExport(filters)}
          />
        </form>
      </PaperWrapper>
    );
  },
);

PreInscriptionSummaryFilters.propTypes = FilterListShape;

export default PreInscriptionSummaryFilters;
