import React from 'react';

import { TableBody } from '@mui/material';
import PropTypes from 'prop-types';

import { IStaffStatistics } from '../../../../types/staff-statistics.type';
import { StaffStatisticsShape } from '../../shapes';
import StaffStatisticsItem from '../StaffStatisticsItem';
import StaffStatisticsListGroup from '../StaffStatisticsListGroup';

interface IProps {
  statistics: IStaffStatistics[];
}

const StaffStatisticsListBody: React.FC<IProps> = React.memo(({ statistics }) => {
  const renderStatistics = React.useMemo(() => {
    if (!statistics?.length) {
      return null;
    }

    return statistics.map((item) => (
      <React.Fragment key={`list_${item.groupCode}_${item.productType}`}>
        <StaffStatisticsItem item={item} />
        <StaffStatisticsListGroup item={item} />
      </React.Fragment>
    ));
  }, [statistics]);

  return <TableBody>{renderStatistics}</TableBody>;
});

StaffStatisticsListBody.propTypes = {
  statistics: PropTypes.arrayOf(StaffStatisticsShape.isRequired).isRequired,
};

export default StaffStatisticsListBody;
