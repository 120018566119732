import React from 'react';

import { TableCell, TableHead, TableRow, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { useTranslation } from 'react-i18next';

import { OptionsShape } from '../../../Staff/shapes';
import { IOptions } from '../../../Staff/types';

const sxTableHead: SxProps<Theme> = { '& th': { fontWeight: 'bold', textAlign: 'center' } };

interface IProps {
  options: IOptions;
}

const StaffStudentsListHead: React.FC<IProps> = React.memo(({ options }) => {
  const { t } = useTranslation();

  return (
    <TableHead>
      <TableRow sx={sxTableHead}>
        <TableCell>{t(`general.school`)}</TableCell>
        <TableCell>{t(`student.id`)}</TableCell>
        <TableCell>{t(`student.last_name`)}</TableCell>
        <TableCell>{t(`student.first_name`)}</TableCell>
        <TableCell>{t(`parent.last_name`)}</TableCell>
        <TableCell>{t(`parent.first_name`)}</TableCell>
        <TableCell>{t(`parent.email`)}</TableCell>
        <TableCell>{t(`general.school_level`)}</TableCell>
        <TableCell>{t(`general.product_type`)}</TableCell>
        <TableCell>{t(`general.registration_type`)}</TableCell>

        {options.displayPartnership && <TableCell>{t(`general.partnerships`)}</TableCell>}

        <TableCell>{t('general.school_year')}</TableCell>
        <TableCell>{t('general.student_status')}</TableCell>
      </TableRow>
    </TableHead>
  );
});

StaffStudentsListHead.propTypes = {
  options: OptionsShape.isRequired,
};

export default StaffStudentsListHead;
