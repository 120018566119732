import React from 'react';

import { TableCell, Theme } from '@mui/material';
import { SxProps } from '@mui/system';

import TableGroupRow from '../../../../components/TableGroupRow';
import { IStaffStatistics } from '../../../../types/staff-statistics.type';
import { StaffStatisticsShape } from '../../shapes';

const sxNumberCell: SxProps<Theme> = { fontWeight: 'bold', textAlign: 'center' };

interface IProps {
  item: IStaffStatistics;
}

const StaffStatisticsListGroup: React.FC<IProps> = React.memo(({ item }) => (
  <TableGroupRow groupTitle={item.groupCode} groupRowColSpan={2}>
    {Object.entries(item.status).map(([name, status]) => (
      <TableCell key={`status_${item.groupCode}_${name}`} sx={sxNumberCell}>
        {status}
      </TableCell>
    ))}
    <TableCell sx={sxNumberCell}>{item.total}</TableCell>
  </TableGroupRow>
));

StaffStatisticsListGroup.propTypes = {
  item: StaffStatisticsShape.isRequired,
};

export default StaffStatisticsListGroup;
