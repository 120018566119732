import React from 'react';

import { RouteProps } from 'react-router-dom';

import Dashboard from './views/Dashboard';
import Inscriptions from './views/Inscriptions';
import InscriptionsDetails from './views/InscriptionsDetails';
import PreInscriptions from './views/PreInscriptions';
import PreInscriptionsSummary from './views/PreInscriptionsSummary';
import Settings from './views/Settings';
import Staff from './views/Staff';
import StaffEvolution from './views/StaffEvolution';
import StaffStatistics from './views/StaffStatistics';
import StaffStudents from './views/StaffStudents';
import Status from './views/Status';

type IRoute = RouteProps & { name: string };

const ROUTES_LIST: IRoute[] = [
  {
    path: '/',
    element: <Dashboard />,
    name: 'dashboard',
  },
  {
    path: '/staff',
    element: <Staff />,
    name: 'staff',
  },
  {
    path: '/staff/students',
    element: <StaffStudents />,
    name: 'staff_students',
  },
  {
    path: '/staff/evolution',
    element: <StaffEvolution />,
    name: 'staff_evolution',
  },
  {
    path: '/staff/statistics',
    element: <StaffStatistics />,
    name: 'staff_statistics',
  },
  {
    path: '/status',
    element: <Status />,
    name: 'status',
  },
  {
    path: '/inscriptions',
    element: <Inscriptions />,
    name: 'inscriptions',
  },
  {
    path: '/inscriptions/details',
    element: <InscriptionsDetails />,
    name: 'inscriptions_details',
  },
  {
    path: '/pre-inscriptions',
    element: <PreInscriptions />,
    name: 'pre_inscriptions',
  },
  {
    path: '/pre-inscriptions/summary',
    element: <PreInscriptionsSummary />,
    name: 'pre_inscriptions_summary',
  },
  {
    path: '/settings',
    element: <Settings />,
    name: 'settings',
  },
];

export default ROUTES_LIST;
