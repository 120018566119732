import React from 'react';

import { TableCell, TableRow, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { useTranslation } from 'react-i18next';

import { ProductTypeShape } from '../../../../shapes/product-type.shape';
import { IInscription } from '../../../../types/inscription.type';
import { IProductType } from '../../../../types/product-type.type';
import { InscriptionShape } from '../../shapes';

const sxCell: SxProps<Theme> = { textAlign: 'center' };
const sxNumberCell: SxProps<Theme> = { textAlign: 'center', fontWeight: 'bold' };

interface IProps {
  productType: IProductType;
  inscription: IInscription;
}

const InscriptionListItem: React.FC<IProps> = React.memo(({ inscription, productType }) => {
  const { t } = useTranslation();

  return (
    <TableRow>
      <TableCell sx={sxCell}>{inscription.school}</TableCell>
      <TableCell sx={sxCell}>{inscription.schoolYear}</TableCell>

      <TableCell sx={sxCell}>
        {t(`product_type.${inscription.productType.toLowerCase()}`)}
      </TableCell>

      <TableCell sx={sxCell}>{inscription.schoolLevel}</TableCell>

      {productType === IProductType.DISE && (
        <>
          <TableCell sx={sxCell}>{inscription.discipline}</TableCell>
          <TableCell sx={sxCell}>{inscription.taxon}</TableCell>
        </>
      )}

      <TableCell sx={sxNumberCell}>{inscription.quantity}</TableCell>
    </TableRow>
  );
});

InscriptionListItem.propTypes = {
  productType: ProductTypeShape.isRequired,
  inscription: InscriptionShape.isRequired,
};

export default InscriptionListItem;
