import React from 'react';

import { TableCell, TableRow, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { IPreInscription } from '../../../../types/pre-inscription.type';
import { PreInscriptionShape } from '../../shapes';

const sxCell: SxProps<Theme> = { textAlign: 'center' };

interface IProps {
  inscription: IPreInscription;
}

const stringKeys: Array<keyof Omit<IPreInscription, 'student'>> = [
  'schoolLevel',
  'reason',
  'academy',
  'department',
  'situation',
  'meetingDate',
  'dossierComment',
  'depasseComment',
  'responseDa',
  'responseDaComment',
  'responseDaDate',
  'inscription',
  'envoiRapo',
  'envoiRapoComment',
  'envoiRapoDate',
  'responseRapo',
  'responseRapoComment',
  'responseRapoDate',
  'recoursTa',
  'recoursTaComment',
  'filingDate',
  'referredComment',
  'audienceDate',
  'responseTa',
  'responseTaComment',
  'refundComment',
];

const PreInscriptionListItem: React.FC<IProps> = React.memo(({ inscription }) => {
  const { t } = useTranslation();
  const { student } = inscription;
  const { parent } = student || {};

  return (
    <TableRow>
      <TableCell sx={sxCell}>{inscription.school}</TableCell>

      <TableCell sx={sxCell}>
        {inscription.paymentDate ? dayjs(inscription.paymentDate).format('DD/MM/YYYY') : ''}
      </TableCell>

      <TableCell sx={sxCell}>{inscription.schoolYear}</TableCell>

      <TableCell sx={sxCell}>
        {parent?.firstName} {parent?.lastName}
      </TableCell>

      <TableCell sx={sxCell}>{parent?.email}</TableCell>
      <TableCell sx={sxCell}>{t(`general.${inscription.registrationType}_item`)}</TableCell>

      <TableCell sx={sxCell}>
        {student?.firstName} {student?.lastName}
      </TableCell>

      {stringKeys.map((key) => (
        <TableCell key={key} sx={sxCell}>
          {inscription?.[key]}
        </TableCell>
      ))}
    </TableRow>
  );
});

PreInscriptionListItem.propTypes = {
  inscription: PreInscriptionShape.isRequired,
};

export default PreInscriptionListItem;
