import React from 'react';

import { TableCell, TableHead, TableRow, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { useTranslation } from 'react-i18next';

const fields = [
  'reason',
  'academy',
  'department',
  'situation',
  'meeting_date',
  'dossier_comment',
  'depasse_comment',
  'response_da',
  'response_da_comment',
  'response_da_date',
  'inscription',
  'envoi_rapo',
  'envoi_rapo_comment',
  'envoi_rapo_date',
  'response_rapo',
  'response_rapo_comment',
  'response_rapo_date',
  'recours_ta',
  'recours_ta_comment',
  'filing_date',
  'referred_comment',
  'audience_date',
  'response_ta',
  'response_ta_comment',
  'refund_comment',
];

const sxTableHead: SxProps<Theme> = { '& th': { fontWeight: 'bold', textAlign: 'center' } };

const PreInscriptionListHead = React.memo(() => {
  const { t } = useTranslation();

  return (
    <TableHead>
      <TableRow sx={sxTableHead}>
        <TableCell>{t(`general.school`)}</TableCell>
        <TableCell>{t(`general.date`)}</TableCell>
        <TableCell>{t(`general.school_year`)}</TableCell>
        <TableCell>{t(`pre_inscriptions.parent_name`)}</TableCell>
        <TableCell>{t(`pre_inscriptions.parent_email`)}</TableCell>
        <TableCell>{t(`general.registration_type`)}</TableCell>
        <TableCell>{t(`pre_inscriptions.student`)}</TableCell>
        <TableCell>{t(`general.school_level`)}</TableCell>

        {fields.map((field) => (
          <TableCell key={`pre_inscriptions_${field}`}>{t(`pre_inscriptions.${field}`)}</TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
});

export default PreInscriptionListHead;
