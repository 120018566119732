import PropTypes from 'prop-types';

import { ProductTypeShape } from '../../shapes/product-type.shape';
import { StudentShape } from '../../shapes/student.shape';

export const InscriptionShape = PropTypes.shape({
  school: PropTypes.string.isRequired,
  schoolYear: PropTypes.string.isRequired,
  schoolLevel: PropTypes.string.isRequired,
  groupCode: PropTypes.string.isRequired,
  productType: ProductTypeShape.isRequired,
  quantity: PropTypes.number.isRequired,
  discipline: PropTypes.string,
  taxon: PropTypes.string,
  students: PropTypes.arrayOf(StudentShape.isRequired),
});
