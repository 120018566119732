import dayjs from 'dayjs';

import { useUserFilters } from './useUserFilters';

type IFiltersType =
  | 'inscription'
  | 'staff'
  | 'staffEvolution'
  | 'status'
  | 'staffStatistics'
  | 'preInscription'
  | 'preInscriptionSummary';

export const useFetchDefaultFiltersData = <T extends any>(type: IFiltersType): T => {
  const filters = useUserFilters();

  const school = '';
  const schoolYear = filters?.schoolYear ?? '';
  const dateFrom = filters?.dateFrom ? dayjs(filters.dateFrom) : dayjs().subtract(1, 'month');
  const dateTo = dayjs();

  const dateComparisonFrom = filters?.dateFromComparison
    ? dayjs(filters.dateFromComparison)
    : dayjs().subtract(1, 'year').subtract(1, 'month');

  const period1SchoolYear = +(filters?.schoolYear ?? dayjs().format('YYYY'));
  const subtractYear = period1SchoolYear - +(filters?.schoolYearComparison ?? period1SchoolYear);
  const dateComparisonTo = dayjs().subtract(subtractYear, 'year');

  switch (type) {
    case 'inscription':
      return {
        school,
        schoolYear,
        schoolLevel: [],
        discipline: [],
        taxon: [],
      } as T;

    case 'staff':
      return {
        dateFrom,
        dateTo,
        productType: '',
        registrationType: '',
        school,
        schoolLevel: [],
        schoolYear,
        partnerships: [],
        withoutPartnerships: false,
        studentStatus: '',
      } as T;

    case 'staffEvolution':
      return {
        productType: '',
        registrationType: '',
        school: '',
        schoolLevel: [],
        period1SchoolYear: filters?.schoolYear ?? '',
        period1DateFrom: dateFrom,
        period1DateTo: dateTo,
        period2SchoolYear: filters?.schoolYearComparison ?? '',
        period2DateFrom: dateComparisonFrom,
        period2DateTo: dateComparisonTo,
      } as T;

    case 'status':
      return {
        school,
        dateFrom,
        dateTo,
        schoolYear,
      } as T;

    case 'staffStatistics':
      return {
        dateFrom,
        dateTo,
        productType: '',
        registrationType: '',
        school: '',
        schoolLevel: [],
        schoolYear,
        partnerships: [],
        withoutPartnerships: false,
        studentStatus: '',
      } as T;

    case 'preInscription':
      return {
        school,
        schoolYear: filters?.schoolYear ?? '',
        registrationType: '',
        dateFrom,
        dateTo,
        schoolLevel: [],
        academy: [],
        department: [],
        reason: [],
        situation: [],
        responseDa: '',
        inscription: '',
        envoiRapo: '',
        responseRapo: '',
        recoursTa: '',
        responseTa: '',
      } as T;

    case 'preInscriptionSummary':
      return {
        school,
        schoolYear: filters?.schoolYear ?? '',
        groupCode: [],
      } as T;

    default:
      return {} as T;
  }
};
