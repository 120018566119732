import React from 'react';

import { TableCell } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import TableTotalFooter from '../../../../components/TableTotalFooter';
import { StaffEvolutionHelper } from '../../../../helpers/staff-evolution.helper';
import { IProductType } from '../../../../types/product-type.type';
import { IStaffEvolution } from '../../../../types/staff-evolution.type';
import { StaffEvolutionShape } from '../../shapes';

interface IProps {
  evolution: IStaffEvolution[];
  displayProductType?: string;
}

const StaffEvolutionListFooter: React.FC<IProps> = React.memo(
  ({ evolution, displayProductType }) => {
    const { t } = useTranslation();

    const renderTotalRow = React.useMemo(() => {
      const { coco, dise, certificate, total } = StaffEvolutionHelper.calculateTotal(evolution);

      return (
        <>
          <TableCell sx={{ fontWeight: 'bold' }}>{t(`general.total`)}</TableCell>

          {(!displayProductType?.length || displayProductType === IProductType.COCO) && (
            <>
              <TableCell>{coco.period1}</TableCell>
              <TableCell>{coco.period2}</TableCell>
              <TableCell>{coco.evolution}%</TableCell>
            </>
          )}

          {(!displayProductType?.length || displayProductType === IProductType.DISE) && (
            <>
              <TableCell>{dise.period1}</TableCell>
              <TableCell>{dise.period2}</TableCell>
              <TableCell>{dise.evolution}%</TableCell>
            </>
          )}

          {(!displayProductType?.length || displayProductType === IProductType.CERTIFICATE) && (
            <>
              <TableCell>{certificate.period1}</TableCell>
              <TableCell>{certificate.period2}</TableCell>
              <TableCell>{certificate.evolution}%</TableCell>
            </>
          )}

          {!displayProductType?.length && (
            <>
              <TableCell>{total.period1}</TableCell>
              <TableCell>{total.period2}</TableCell>
              <TableCell>{total.evolution}%</TableCell>
            </>
          )}
        </>
      );
    }, [displayProductType, evolution, t]);

    return <TableTotalFooter>{renderTotalRow}</TableTotalFooter>;
  },
);

StaffEvolutionListFooter.defaultProps = {
  displayProductType: undefined,
};

StaffEvolutionListFooter.propTypes = {
  evolution: PropTypes.arrayOf(StaffEvolutionShape.isRequired).isRequired,
  displayProductType: PropTypes.string,
};

export default StaffEvolutionListFooter;
