import React from 'react';

import { TableCell } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import TableTotalFooter from '../../../../components/TableTotalFooter';
import { StaffStatisticsHelper } from '../../../../helpers/staff-statistics.helper';
import { IStaffStatistics } from '../../../../types/staff-statistics.type';
import { StaffStatisticsShape } from '../../shapes';

interface IProps {
  statistics: IStaffStatistics[];
  statuses: string[];
}

const StaffStatisticsListFooter: React.FC<IProps> = React.memo(({ statistics, statuses }) => {
  const { t } = useTranslation();

  const renderTotalRow = React.useMemo(() => {
    const total = statistics.reduce((value, item) => value + item.total, 0);

    return (
      <>
        <TableCell colSpan={2} sx={{ fontWeight: 'bold' }}>
          {t(`general.total`)}
        </TableCell>

        {statuses.map((status) => (
          <TableCell key={`total_${status}`}>
            {StaffStatisticsHelper.calculateTotalByStatus(statistics, status)}
          </TableCell>
        ))}

        <TableCell>{total}</TableCell>
      </>
    );
  }, [statistics, statuses, t]);

  return <TableTotalFooter>{renderTotalRow}</TableTotalFooter>;
});

StaffStatisticsListFooter.propTypes = {
  statistics: PropTypes.arrayOf(StaffStatisticsShape.isRequired).isRequired,
  statuses: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
};

export default StaffStatisticsListFooter;
