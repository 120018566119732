import React from 'react';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { DrawerProps } from '@mui/material';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import PropTypes from 'prop-types';

import Logo from '../Logo';
import Menu from '../Menu';

interface AppDrawerProps extends DrawerProps {
  open?: boolean;
  width: number;
}

interface IProps {
  open: boolean;
  width: number;
  handleToggle: () => void;
}

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })<AppDrawerProps>(
  ({ theme, open, width }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
      }),
    },
  }),
);

const Sidebar: React.FC<IProps> = React.memo(({ open, width, handleToggle }) => (
  <Drawer variant="permanent" open={open} width={width}>
    <Toolbar
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        px: [1],
      }}
    >
      <Logo />

      <IconButton onClick={handleToggle}>
        <ChevronLeftIcon />
      </IconButton>
    </Toolbar>
    <Divider />
    <Menu />
  </Drawer>
));

Sidebar.propTypes = {
  open: PropTypes.bool.isRequired,
  width: PropTypes.number.isRequired,
  handleToggle: PropTypes.func.isRequired,
};

export default Sidebar;
