import { IStaffStatistics, IStaffStatisticsStatus } from '../types/staff-statistics.type';
import { ArrayUtils } from '../utils/array.utils';

export class StaffStatisticsHelper {
  static calculateTotalByStatus(statistics: IStaffStatistics[], status: string) {
    const statuses = statistics
      .map(({ levels }) => levels.map((level) => level.status).flat())
      .flat();

    return ArrayUtils.calculateTotal<IStaffStatisticsStatus>(statuses, status);
  }
}
