import React from 'react';

import PropTypes from 'prop-types';

import { IPreInscriptionSummaryStatistics } from '../../../../types/pre-inscription-summary.type';
import { IRegistrationType } from '../../../../types/registration-type';
import { PreInscriptionSummaryStatisticsShape } from '../../shapes';
import PreInscriptionListSummaryFraction from '../PreInscriptionListSummaryFraction';
import PreInscriptionListSummaryItem from '../PreInscriptionListSummaryItem';

interface IProps {
  statistics: IPreInscriptionSummaryStatistics;
  type: string;
  groupCode: string;
}

const PreInscriptionListSummarySection: React.FC<IProps> = React.memo(
  ({ groupCode, type, statistics }) => (
    <>
      <PreInscriptionListSummaryItem statistics={statistics} groupCode={groupCode} type={type} />
      <PreInscriptionListSummaryFraction statistics={statistics} />
    </>
  ),
);

PreInscriptionListSummarySection.propTypes = {
  statistics: PreInscriptionSummaryStatisticsShape.isRequired,
  type: PropTypes.oneOf([...Object.keys(IRegistrationType), 'total']).isRequired,
  groupCode: PropTypes.string.isRequired,
};

export default PreInscriptionListSummarySection;
