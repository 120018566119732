import React, { useCallback, useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { useTranslation } from 'react-i18next';

import InscriptionDetailsList from './components/InscriptionDetailsList';
import { downloadInscriptionsDetails, fetchInscriptions } from '../../api/inscription';
import ContentWrapper from '../../components/ContentWrapper';
import EmptyData from '../../components/EmptyData';
import ProgressSection from '../../components/ProgressSection';
import { PRODUCT_TYPE_LIST } from '../../constants/product-type.constant';
import { IInscription } from '../../types/inscription.type';
import { IProductType } from '../../types/product-type.type';
import { HttpUtils } from '../../utils/http.utils';
import InscriptionFilters from '../Inscriptions/components/InscriptionFilters';
import InscriptionTabs from '../Inscriptions/components/InscriptionTabs';
import { IFiltersForm } from '../Inscriptions/types';

const InscriptionsDetails = React.memo(() => {
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = React.useState(0);
  const [productType, setProductType] = React.useState(IProductType.COCO);

  const handleChange = useCallback(
    (event: React.SyntheticEvent, newValue: number) => setActiveTab(newValue),
    [],
  );

  const [inscriptions, setInscriptions] = useState<IInscription[]>([]);
  const [isSubmitProcess, setSubmitProcess] = useState<boolean>(false);
  const [isExportProcess, setExportProcess] = useState<boolean>(false);
  const [isReady, setReady] = useState<boolean>(false);

  useEffect(() => {
    setProductType(IProductType[PRODUCT_TYPE_LIST[activeTab]]);
    setInscriptions([]);
    setReady(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  const handleSubmit = React.useCallback(
    (filters: IFiltersForm) => {
      setSubmitProcess(true);

      if (!isReady) {
        setReady(true);
      }

      fetchInscriptions({
        ...filters,
        productType,
      })
        .then((response) => setInscriptions(response))
        .catch(() => setInscriptions([]))
        .finally(() => setSubmitProcess(false));
    },
    [isReady, productType],
  );

  const handleExport = React.useCallback(
    (filters: IFiltersForm) => {
      setExportProcess(true);

      if (!isReady) {
        setReady(true);
      }

      downloadInscriptionsDetails({
        ...filters,
        productType,
      })
        .then((response) =>
          HttpUtils.handleBlob(response, `inscriptions_details_${productType.toLowerCase()}.xlsx`),
        )
        .finally(() => setExportProcess(false));
    },
    [isReady, productType],
  );

  const renderList = React.useMemo(() => {
    if (!isReady) {
      return null;
    }

    if (isSubmitProcess) {
      return <ProgressSection mt={3} />;
    }

    if (!inscriptions.length) {
      return <EmptyData />;
    }

    return <InscriptionDetailsList inscriptions={inscriptions} />;
  }, [inscriptions, isReady, isSubmitProcess]);

  return (
    <ContentWrapper title={t('inscriptions_details.title')}>
      <Paper>
        <InscriptionTabs types={PRODUCT_TYPE_LIST} activeTab={activeTab} onChange={handleChange} />

        <Box sx={{ p: 2 }}>
          <InscriptionFilters
            productType={productType}
            onSubmit={handleSubmit}
            onExport={handleExport}
            isSubmitProcess={isSubmitProcess}
            isExportProcess={isExportProcess}
          />
        </Box>
      </Paper>

      {renderList}
    </ContentWrapper>
  );
});

export default InscriptionsDetails;
