import { IFormSelectItem } from '../../types/form-select-item.type';

export const RESPONSE_DA_LIST: IFormSelectItem[] = [
  { code: 'yes', name: 'general.yes' },
  { code: 'no', name: 'general.no' },
  { code: 'no_da', name: 'pre_inscriptions.no_da' },
];

export const INSCRIPTION_LIST: IFormSelectItem[] = [
  { code: 'yes', name: 'general.yes' },
  { code: 'no', name: 'general.no' },
  { code: 'other', name: 'general.other' },
  { code: 'back_to_ordinary_school', name: 'pre_inscriptions.back_to_ordinary_school' },
];

export const ENVOI_RAPO_LIST: IFormSelectItem[] = [
  { code: 'yes', name: 'general.yes' },
  { code: 'no', name: 'general.no' },
  { code: 'yes_by_themselves', name: 'pre_inscriptions.yes_by_themselves' },
];

export const RESPONSE_RAPO_LIST: IFormSelectItem[] = [
  { code: 'accepted', name: 'pre_inscriptions.accepted' },
  { code: 'refused', name: 'pre_inscriptions.refused' },
  { code: 'no_answer', name: 'pre_inscriptions.no_answer' },
];

export const RECOURS_TA_LIST: IFormSelectItem[] = [
  { code: 'yes', name: 'general.yes' },
  { code: 'no', name: 'general.no' },
];

export const RESPONSE_TA_LIST: IFormSelectItem[] = [
  { code: 'positive', name: 'pre_inscriptions.positive' },
  { code: 'refused', name: 'pre_inscriptions.refused' },
];
