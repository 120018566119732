import React from 'react';

import { TableBody } from '@mui/material';
import PropTypes from 'prop-types';

import { IInscription } from '../../../../types/inscription.type';
import { InscriptionShape } from '../../../Inscriptions/shapes';
import InscriptionDetailsListItem from '../InscriptionDetailsListItem';

interface IProps {
  inscriptions: IInscription[];
}

const InscriptionDetailsListBody: React.FC<IProps> = React.memo(({ inscriptions }) => {
  const renderStaff = React.useMemo(() => {
    if (!inscriptions?.length) {
      return null;
    }

    return inscriptions.map((inscription) =>
      inscription
        .students!.map((student) => (
          <InscriptionDetailsListItem
            key={`student_${student.id}`}
            student={student}
            inscription={inscription}
          />
        ))
        .flat(),
    );
  }, [inscriptions]);

  return <TableBody>{renderStaff}</TableBody>;
});

InscriptionDetailsListBody.propTypes = {
  inscriptions: PropTypes.arrayOf(InscriptionShape.isRequired).isRequired,
};

export default InscriptionDetailsListBody;
