import React from 'react';

import DatasetIcon from '@mui/icons-material/Dataset';
import GroupsIcon from '@mui/icons-material/Groups';
import HomeIcon from '@mui/icons-material/Home';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import List from '@mui/material/List';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import './style.scss';

const menuItems = [
  { route: '/', icon: <HomeIcon />, label: 'dashboard' },
  { route: '/staff', icon: <RecentActorsIcon />, label: 'staff' },
  { route: '/staff/students', icon: <DatasetIcon />, label: 'staff_students' },
  { route: '/staff/evolution', icon: <RecentActorsIcon />, label: 'staff_evolution' },
  { route: '/status', icon: <GroupsIcon />, label: 'status' },
  { route: '/staff/statistics', icon: <RecentActorsIcon />, label: 'staff_statistics' },
  { route: '/inscriptions', icon: <HowToRegIcon />, label: 'inscriptions' },
  { route: '/inscriptions/details', icon: <HowToRegIcon />, label: 'inscriptions_details' },
  { route: '/pre-inscriptions', icon: <HowToRegIcon />, label: 'pre_inscriptions' },
  { route: '/pre-inscriptions/summary', icon: <HowToRegIcon />, label: 'pre_inscriptions_summary' },
];

const Menu: React.FC = React.memo(() => {
  const { t } = useTranslation();

  return (
    <List component="nav">
      {menuItems.map((item) => (
        <ListItemButton key={item.label} component={NavLink} to={item.route} end>
          <ListItemIcon>{item.icon}</ListItemIcon>
          <ListItemText primary={t(`menu.${item.label}`)} sx={{ color: '#636363' }} />
        </ListItemButton>
      ))}
    </List>
  );
});

export default Menu;
